
<template>
  <span></span>
</template>
<script>
import store from '@/store'
import jsPDF from 'jspdf'
import moment from 'moment'
import FuncionesGenerales from '@/funciones/funciones'
import autoTable from 'jspdf-autotable'
export default {
  setup() {
    const datosInit = {
      nombreDocumento: 'nombre',
      cabecera1: {
        visible: true,
      },
      titulo: {
        visible: true,
        posicion: 'center',
        texto: 'Recibo #1000',
        TamanoLetras: 14,
        color: '#FF0000',
      },
      cabecera2: {
        visible: true,
        datos: [
          { texto: 'Recibo #1000', TamanoLetras: 14, color: '#FF0000' },
          { texto: 'Fecha: 02/11/2022' },
          { texto: 'Hora: 03:07:21 p. m.' },
        ],
      },
      sub1: {
        visible: true,
        datos: [
          { texto: 'Documento: 24691627' },
          { texto: 'Cliente: Dilmer Rojo' },
          { texto: 'Telefono:' },
          { texto: 'Direccion:' },
        ],
      },
      sub2: {
        visible: true,
        datos: [
          { texto: 'Total deuda: $195.58' },
          { texto: 'Desde: 13/09/2022' },
          { texto: 'Hasta: 18/09/2022:' },
          { texto: 'Cant Comandas: 3' },
        ],
      },
      cuerpo1: {
        visible: true,
        datos: [
          { texto: 'Texto 1', TamanoLetras: 14, color: '#FF0000' },
          { texto: 'Texto 2' },
          { texto: 'Texto 3' },
          { texto: 'Texto 4' },
          { texto: ' ' },
          {
            titulo: { texto: 'titulo 1', TamanoLetras: 14, color: '#FF0000' },
            columnStyles: {
              0: {
                halign: 'left',
              },
              1: {
                halign: 'right',
              },
            },
            tablas: {
              cant: 2,
              id: 1,
            },
            head: [
              [
                { content: 'Campo1', styles: { halign: 'left' } },
                { content: 'Campo1', styles: { halign: 'right' } },
              ],
            ],
            body: [
              ['valor 1', 'valor2'],
              ['valor 1', 'valor2'],
              ['valor 1', 'valor2'],
              ['valor 1', 'valor2'],
            ],
          },
          {
            titulo: { texto: 'titulo 2', TamanoLetras: 14, color: '#FF0000' },
            columnStyles: {
              0: {
                halign: 'left',
              },
              1: {
                halign: 'right',
              },
            },
            tablas: {
              cant: 2,
              id: 2,
            },
            head: [
              [
                { content: 'Campo1', styles: { halign: 'left' } },
                { content: 'Campo1', styles: { halign: 'right' } },
              ],
            ],
            body: [
              ['valor 1', 'valor2'],
              ['valor 1', 'valor2'],
              ['valor 1', 'valor2'],
              ['valor 1', 'valor2'],
              ['valor 1', 'valor2'],
              ['valor 1', 'valor2'],
            ],
          },
          {
            titulo: { texto: 'titulo 3', TamanoLetras: 14, color: '#FF0000' },
            columnStyles: {
              0: {
                halign: 'left',
              },
              1: {
                halign: 'right',
              },
            },
            tablas: {},
            head: [
              [
                { content: 'Campo1', styles: { halign: 'left' } },
                { content: 'Campo1', styles: { halign: 'right' } },
              ],
            ],
            body: [
              ['valor 1', 'valor2'],
              ['valor 1', 'valor2'],
              ['valor 1', 'valor2'],
              ['valor 1', 'valor2'],
              ['valor 1', 'valor2'],
              ['valor 1', 'valor2'],
            ],
          },
        ],
      },
    }
    const TamanoLetras = {
      texto: 10,
      titulo: 12,
      subtitulo: 11,
      comentario: 10,
    }

    var lineas = {
      cabecera1: 30,
      cabecera2: 75,
      sub1: 0,
      sub2: 0,
      cuerpo1: 0,
    }
    const reset = () => {
      lineas = {
        cabecera1: 30,
        cabecera2: 75,
        sub1: 0,
        sub2: 0,
        cuerpo1: 0,
      }
    }

    const sumLinea = (tipo, valor) => {
      if (tipo == 1) {
        lineas.cabecera1 += valor
        return lineas.cabecera1
      } else if (tipo == 2) {
        lineas.cabecera2 += valor
        return lineas.cabecera2
      } else if (tipo == 3) {
        lineas.sub1 += valor
        return lineas.sub1
      } else if (tipo == 4) {
        lineas.sub2 += valor
        return lineas.sub2
      } else if (tipo == 5) {
        lineas.cuerpo1 += valor
        return lineas.cuerpo1
      } else {
        return 0
      }
    }
    const generarPDF = (datos = datosInit) => {
      reset()
      const orientacion = datos.orientacion ? datos.orientacion : 'p'
      console.log(store.state.user.empresaActual)
      //const pdf = new jsPDF('l', 'pt', 'A4'); horizontal
      //const pdf = new jsPDF('p', 'pt', 'A4')
      const pdf = new jsPDF(datos.orientacion ? datos.orientacion : 'p', 'pt', 'A4',true)
      pdf.setFontSize(TamanoLetras.texto)
      pdf.setTextColor('#5E5669')
      pdf.setFont('Helvetica', 'bold')

      //  datos de la empresa
      if (datos.cabecera1.visible == true) {
        if (store.state.user.empresaActual.imagen2 != null && store.state.user.empresaActual.imagen2 != undefined) {
          pdf.addImage(store.state.user.empresaActual.imagen2, 'JPEG', 40, sumLinea(1, 0), 80, 30)
        }
        pdf.setFontSize(TamanoLetras.titulo)
        pdf.text(store.state.user.empresaActual.razonSocial, 40, sumLinea(1, 45))
        pdf.setFontSize(TamanoLetras.texto)
        pdf.setFont('Helvetica', 'normal')
        pdf.text(store.state.user.empresaActual.documento, 40, sumLinea(1, 12)) 
        pdf.text(store.state.sucursalSelect.nombre, 40, sumLinea(1, 12)) /*/*
        pdf.text(FuncionesGenerales.camelCase(store.state.user.empresaActual.direccion1, true), 40, sumLinea(1, 12))
        pdf.text(FuncionesGenerales.camelCase(store.state.user.empresaActual.direccion2, true), 40, sumLinea(1, 12))
        pdf.text(FuncionesGenerales.camelCase(store.state.user.empresaActual.direccion3), 40, sumLinea(1, 12))*/
        // pdf.text(FuncionesGenerales.camelCase(store.state.user.empresaActual.telefonoResponsable), 40, sumLinea(1, 12))
      }
      //  datos de la empresa
      pdf.setFont('Helvetica', 'normal')
      //  cabecera 2
      if (datos.cabecera2.visible == true) {
        datos.cabecera2.datos.forEach((item, i) => {
          if (item.TamanoLetras != undefined) {
            pdf.setFontSize(item.TamanoLetras)
          } else {
            pdf.setFontSize(TamanoLetras.texto)
          }
          if (item.color != undefined) {
            pdf.setTextColor(item.color)
          } else {
            pdf.setTextColor('#5E5669')
          }
          pdf.text(item.texto, 400, sumLinea(2, i == 0 ? 0 : 12), null, null, 'left')
        })
      }

      //  cabecera 2

      //linea cabecera
      pdf.setDrawColor('#D3D3D3')
      const line = lineas.cabecera1 > lineas.cabecera2 ? sumLinea(1, 5) : sumLinea(2, 5)
      pdf.line(40, line, orientacion == 'p' ? 560 : 800, line)

      //linea cabecera
      // titulo
      if (datos.titulo.visible == true) {
        pdf.setFont('Helvetica', 'bold')
        if (datos.titulo.TamanoLetras != undefined) {
          pdf.setFontSize(datos.titulo.TamanoLetras)
        } else {
          pdf.setFontSize(datos.titulo.texto)
        }
        if (datos.titulo.color != undefined) {
          pdf.setTextColor(datos.titulo.color)
        } else {
          pdf.setTextColor('#5E5669')
        }
        let x = 40
        if (datos.titulo.posicion == 'left') {
          x = 40
        } else if (datos.titulo.posicion == 'right') {
          x = orientacion == 'p' ? 550 : 800
        } else if (datos.titulo.posicion == 'center') {
          x = orientacion == 'p' ? 300 : 400
        }
        pdf.text(
          datos.titulo.texto,
          x,
          lineas.cabecera1 > lineas.cabecera2 ? sumLinea(1, 12) : sumLinea(2, 12),
          null,
          null,
          datos.titulo.posicion,
        )
        // titulo
        pdf.setFont('Helvetica', 'normal')
        pdf.setDrawColor('#D3D3D3')
        const line = lineas.cabecera1 > lineas.cabecera2 ? sumLinea(1, 5) : sumLinea(2, 5)
        pdf.line(40, line, orientacion == 'p' ? 560 : 800, line)
      }

      //sub 1
      if (datos.sub1.visible == true) {
        lineas.sub1 = (lineas.cabecera1 > lineas.cabecera2 ? lineas.cabecera1 : lineas.cabecera2) + 5
        datos.sub1.datos.forEach((item, i) => {
          if (item.TamanoLetras != undefined) {
            pdf.setFontSize(item.TamanoLetras)
          } else {
            pdf.setFontSize(TamanoLetras.texto)
          }
          if (item.color != undefined) {
            pdf.setTextColor(item.color)
          } else {
            pdf.setTextColor('#5E5669')
          }
          pdf.text(item.texto, 40, sumLinea(3, 12), null, null, 'left')
        })
      }

      //sub 1

      //sub 2

      if (datos.sub2.visible == true) {
        lineas.sub2 = (lineas.cabecera1 > lineas.cabecera2 ? lineas.cabecera1 : lineas.cabecera2) + 5
        datos.sub2.datos.forEach((item, i) => {
          if (item.TamanoLetras != undefined) {
            pdf.setFontSize(item.TamanoLetras)
          } else {
            pdf.setFontSize(TamanoLetras.texto)
          }
          if (item.color != undefined) {
            pdf.setTextColor(item.color)
          } else {
            pdf.setTextColor('#5E5669')
          }
          pdf.text(item.texto, orientacion == 'p' ? 400 : 640, sumLinea(4, 12), null, null, 'left')
        })
      }
      if (datos.sub1.visible == false && datos.sub2.visible == false) {
        lineas.sub1 = lineas.cabecera1 > lineas.cabecera2 ? lineas.cabecera1 : lineas.cabecera2
      } else {
        if (lineas.sub2 > 0 || lineas.sub1 > 0) {
          pdf.setDrawColor('#D3D3D3')
          const line = lineas.sub1 > lineas.sub2 ? sumLinea(3, 5) : sumLinea(4, 5)
          pdf.line(40, line, orientacion == 'p' ? 560 : 800, line)
        }
      }

      //sub 2

      //linea sub

      if (datos.cuerpo1.visible == true) {
        lineas.cuerpo1 = (lineas.sub1 > lineas.sub2 ? lineas.sub1 : lineas.sub2) + 5
        datos.cuerpo1.datos.forEach((item, i) => {
          if (item.texto != undefined) {
            if (item.TamanoLetras != undefined) {
              pdf.setFontSize(item.TamanoLetras)
            } else {
              pdf.setFontSize(TamanoLetras.texto)
            }
            if (item.color != undefined) {
              pdf.setTextColor(item.color)
            } else {
              pdf.setTextColor('#5E5669')
            }
            pdf.text(item.texto, 40, sumLinea(5, 12), null, null, 'left')
          } else if (item.linea != undefined) {
            pdf.setDrawColor('#D3D3D3')
            const line = sumLinea(5, 12)
            pdf.line(40, line, orientacion == 'p' ? 560 : 800, line)
          } else {
            //tabla

            let margin = { left: 40 }
            let tableWidth = 'auto'
            let startYmargen = 0
            if (item.tablas.cant != undefined) {
              if (item.tablas.id == 2) {
                margin = { left: orientacion == 'p' ? 320 : 560 }
              } else {
                margin = { left: 40 }
              }
              tableWidth = orientacion == 'p' ? 235 : 470
            } else {
              margin = { left: 40 }
              tableWidth = 'auto'
            }

            if (item.titulo.texto != undefined) {
              if (item.titulo.TamanoLetras != undefined) {
                pdf.setFontSize(item.titulo.TamanoLetras)
              } else {
                pdf.setFontSize(TamanoLetras.texto)
              }
              if (item.titulo.color != undefined) {
                pdf.setTextColor(item.titulo.color)
              } else {
                pdf.setTextColor('#5E5669')
              }

              pdf.text(item.titulo.texto, margin.left, sumLinea(5, margin.left == 40 ? 12 : -10), null, null, 'left')
            }

            startYmargen = 5
            autoTable(pdf, {
              headStyles: {
                fillColor: [250, 250, 250],
                textColor: [94, 86, 105],
                lineWidth: {
                  right: 0,
                  bottom: 1,
                  top: 1,
                  left: 0,
                },
              },
              alternateRowStyles: {
                fillColor: [250, 250, 250],
                lineColor: [211, 211, 211],
                lineWidth: {
                  right: 0,
                  bottom: 0.5,
                  top: 0.5,
                  left: 0,
                },
              },
              bodyStyles: { lineColor: [0, 0, 0] },
              tableLineWidth: 0,
              styles: item.styles,
              columnStyles: item.columnStyles,
              tableWidth: tableWidth,
              startY: sumLinea(5, item.titulo.texto != undefined ? startYmargen + 5 : startYmargen),
              margin: margin,
              head: item.head,
              body: item.body,

              didDrawPage: function (data) {
                // Footer
                var str = 'Pagina ' + pdf.internal.getNumberOfPages()
                // Total page number plugin only available in jspdf v1.0+
                if (typeof pdf.putTotalPages === 'function') {
                  str = str
                }
                pdf.setFontSize(7)

                // jsPDF 1.4+ uses getHeight, <1.4 uses .height
                var pageSize = pdf.internal.pageSize
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                pdf.text(str, data.settings.margin.left, pageHeight - 20)
                pdf.text(
                  store.state.user.nombre +
                    ' ' +
                    store.state.user.apellido +
                    ' ' +
                    moment().format('MM/DD/YYYY, h:mm:ss a'),
                  orientacion == 'p' ? 550 : 800,
                  pageHeight - 20,
                  null,
                  null,
                  'right',
                )
              },
            })

            if (item.tablas.cant == undefined) {
              lineas.cuerpo1 = pdf.autoTable.previous.finalY + 10
            } else {
              if (item.tablas.id == 2) {
                lineas.cuerpo1 = pdf.autoTable.previous.finalY + 10
              }
            }
          }
        })
      }

      //linea sub
      pdf.save(`${store.state.sucursalSelect.nombre +' '+ datos.nombreDocumento}.pdf`)
    }

    return {
      generarPDF,
      sumLinea,
    }
  },
}
</script>